<template>
  <div class="operateExe">
    <a-row type="flex" :gutter="[20, 10]" align="middle">
      <a-col :span="5">
        <a-range-picker :value="checkTimeRange" :ranges="timeRange" @change="onTimeChange" :allowClear="false" />
      </a-col>
      <a-col :span="5">
        <a-select
          v-model="form.operatorId"
          style="width: 100%"
          placeholder="运营人"
          show-search
          option-filter-prop="children"
          @change="searchAllList()"
          allowClear
        >
          <a-select-option v-for="item in allMemberList" :key="item.id" :value="item.id">{{
            item.operatorName
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="5">
        <a-input-search
          placeholder="小红书号/昵称"
          v-model.trim="form.authorSearch"
          @search="searchAllList()"
          allowClear
        ></a-input-search>
      </a-col>
      <a-col :span="5">
        <a-select
          style="width: 236px; z-index: 2"
          v-model="form.operatorGroupId"
          placeholder="请选择团队"
          show-search
          option-filter-prop="children"
          allowClear
          @change="searchAllList()"
        >
          <a-select-option v-for="item in allTeamList" :key="item.id" :value="item.id">{{
            item.groupName
          }}</a-select-option>
        </a-select>
      </a-col>
      <a-button icon="redo" @click="init">重置</a-button>
    </a-row>

    <a-row style="margin-top: 10px">
      <a-tabs default-active-key="ALL" @change="handleTagChange" v-model="currentTab">
        <a-tab-pane v-for="val in publishStatusList" :key="val.value" :tab="`${val.text} ${val.count}`"></a-tab-pane>
      </a-tabs>
    </a-row>

    <a-row type="flex" justify="space-between" align="middle">
      <a-col :span="20" style="display: flex">
        <span style="margin-right: 15px; width: 50px">选题</span>
        <div>
          <a-tag :color="tag.colorHex" v-for="(tag, index) in tagStatInfoList" :key="index" style="margin-bottom: 10px">
            {{ tag.tagName }}&nbsp;{{ tag.count }}
          </a-tag>
        </div>
      </a-col>
      <a-col>
        <a-popover>
          <template slot="content">
            <p>系统每日00:00开始预生产所有新增计划所需的图片和文案</p>
          </template>
          <a-icon style="margin-right: 3px" type="question-circle" />
        </a-popover>
        <a-button :loading="preGenerateLoading" type="primary" @click="doPreGenerate">立即生产内容</a-button>
      </a-col>
    </a-row>
    <div>
      <a-table
        style="margin-top: 10px"
        :loading="tableLoading"
        :columns="columns"
        :data-source="dataList"
        :rowKey="(record) => record.id"
        :pagination="false"
        @change="onTableChange"
      >
        <div slot="preStatus" slot-scope="text, record">
          {{ preStatusList[record.preStatus] }}
        </div>
        <div slot="writtingTheme" slot-scope="text, record">
          <div class="themeList">
            <div class="themeItem" :style="`background-color: ${record.colorHex || '#adafb3'}`">
              {{ record.tagName }}
            </div>
          </div>
        </div>

        <div slot="writtingPreview" slot-scope="text, record">
          <div class="writtingPreview" v-if="record.noteTitle">
            <div
              class="previewLeft"
              :style="`background-image: url('${record.previewImageUrl || ''}')`"
              @click="openCheckWritting(record)"
            ></div>
            <div class="previewRight">
              <div class="rightTitle" @click="openCheckWritting(record)">
                <a-popover>
                  <template slot="content">
                    <p>{{ record.noteTitle }}</p>
                  </template>
                  {{ record.noteTitle }}
                </a-popover>
              </div>
              <div
                class="rightId"
                v-clipboard:copy="record.noteId"
                v-clipboard:success="onCopy"
                v-clipboard:error="onCopyError"
              >
                <div class="rightIdL">ID：{{ record.noteId || '-' }}</div>
                <a-icon v-show="record.noteId" type="copy" />
              </div>
            </div>
          </div>
          <div v-else>
            <a-button v-if="record.noteLink" type="link" @click="openXhsNote(record.noteLink)">跳转笔记</a-button>
            <div v-else>-</div>
          </div>
        </div>

        <div class="media-column" slot="mediaAccount" slot-scope="text, record">
          <div class="media-left" v-show="record.avatar">
            <img :src="record.avatar" alt="头像" referrerpolicy="no-referrer" />
          </div>
          <div class="media-right">
            <div style="margin-left: 15px" class="blue-text" @click="handleJumpXHS(record)">{{ record.nickname }}</div>
            <div class="line-flex right-txt">
              <div class="logo" v-show="record.authorCode">
                <img src="@/assets/icon/xhs_logo.png" alt="" />
              </div>
              <div>{{ record.authorCode || '-' }}</div>
            </div>
          </div>
        </div>

        <!-- <div class="media-column" slot="characters" slot-scope="text, record">
          <div class="avatar-left" v-show="record.photoUrl">
            <img :src="record.photoUrl" alt="头像" referrerpolicy="no-referrer" />
          </div>
          <div class="avatar-right">
            <div>
              {{ findPersonalityType(record.personalityType) }}
            </div>
          </div>
        </div> -->

        <div slot="statusTitle">
          <div>
            状态
            <a-popover>
              <template slot="content">
                <div>笔记的发布时间/定时发布时间与选题计划对比：</div>
                <div><span class="status-dot wait"></span>待发布 计划日00:00-24:00无对应选题的笔记</div>
                <div><span class="status-dot done"></span>已发布 计划日00:00-24:00有对应选题的笔记</div>
                <div>
                  <span class="status-dot expire"></span>已过期 计划日00:00-24:00无对应选题的笔记，且当前日期晚于计划日
                </div>
              </template>
              <span style="cursor: pointer">
                <a-icon type="question-circle" />
              </span>
            </a-popover>
          </div>
        </div>

        <div slot="statusData" slot-scope="text, record">
          <div>
            <span :class="['status-dot', findStateType(record.publishStatus).className]"></span
            >{{ findStateType(record.publishStatus).text }}
          </div>
        </div>

        <div slot="operation" slot-scope="text, record">
          <template v-if="record.publishStatus === 'NOT_PUBLISH' && !record.copyWritingContentId && !record.notePushId">
            <a type="link" @click="handlePublish(record)">写笔记</a>
            <a-divider type="vertical" />
          </template>
          <template v-if="record.publishStatus === 'NOT_PUBLISH' && record.copyWritingContentId && !record.notePushId">
            <a type="link" @click="handlePublish(record)">编辑草稿</a>
            <a-divider type="vertical" />
          </template>
          <template v-if="record.publishStatus === 'NOT_PUBLISH' && record.notePushId">
            <a type="link" @click="handleEditPush(record)">编辑笔记</a>
            <a-divider type="vertical" />
          </template>
          <template v-if="record.publishStatus === 'NOT_PUBLISH' || record.publishStatus === 'PUBLISHING'">
            <a type="link" @click="handleTagJump(record)">去选题库</a>
            <a-divider type="vertical" />
          </template>
          <a type="link" @click="handleChangePlan(record)" v-show="record.publishStatus !== 'PUBLISHED'">修改计划</a>
        </div>
      </a-table>
      <base-pagination
        :currentPage="pagination.current"
        :options="['15', '30', '50']"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>

    <!-- 修改计划 -->
    <a-modal
      width="600px"
      :visible="showEditModal"
      title="修改计划"
      @close="handleCancel"
      @cancel="handleCancel"
      :zIndex="999"
    >
      <div style="display: flex; align-items: center; justify-content: space-between; padding: 0 15px">
        <div class="media-column">
          <div class="media-left" v-show="editModalData.avatar">
            <img :src="editModalData.avatar" alt="头像" referrerpolicy="no-referrer" />
          </div>
          <div class="media-right">
            <div style="margin-left: 15px" class="blue-text" @click="handleJumpXHS(editModalData)">
              {{ editModalData.nickname }}
            </div>
            <div class="line-flex right-txt">
              <div class="logo" v-show="editModalData.authorCode">
                <img src="@/assets/icon/xhs_logo.png" alt="" />
              </div>
              <div>{{ editModalData.authorCode || '-' }}</div>
            </div>
          </div>
        </div>
        <div class="media-column" style="width: 160px">
          <div class="avatar-left" v-show="editModalData.photoUrl">
            <img :src="editModalData.photoUrl" alt="头像" referrerpolicy="no-referrer" />
          </div>
          <div class="avatar-right">
            <div>
              {{ findPersonalityType(editModalData.personalityType) }}
            </div>
          </div>
        </div>
      </div>

      <div>
        <a-form-model
          style="width: 100%"
          :model="editModalData"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 18 }"
          :rules="rules"
          ref="form"
        >
          <a-form-model-item label="时间" prop="planPublishDate" required>
            <a-input v-model="editModalData.planPublishDate" disabled></a-input>
          </a-form-model-item>
          <a-form-model-item label="选题" key="topicSelectionTagId" prop="topicSelectionTagId" required>
            <TopicTreeInput
              show-search
              style="width: 100%"
              v-model="editModalData.topicSelectionTagId"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :replaceFields="{ title: 'name' }"
              placeholder="选题"
              tree-default-expand-all
              :maxTagCount="1"
            />
          </a-form-model-item>
          <a-form-model-item label="状态" key="publishStatus" prop="publishStatus">
            <a-select v-model="editModalData.publishStatus">
              <a-select-option
                v-for="val in publishStatusList.filter((s) => s.value !== 'ALL')"
                :key="val.value"
                :value="val.value"
                >{{ val.text }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="渠道" key="publishChannel" prop="publishChannel">
            <a-select v-model="editModalData.publishChannel" allowClear>
              <a-select-option v-for="val in publishChannelList" :key="val.value" :value="val.value">{{
                val.text
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="笔记链接" key="noteLink" prop="noteLink">
            <a-input v-model.trim="editModalData.noteLink"></a-input>
          </a-form-model-item>
          <a-form-model-item label="说明" key="description" prop="description">
            <a-textarea
              v-model.trim="editModalData.description"
              :auto-size="{ minRows: 6, maxRows: 8 }"
              :maxLength="300"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <template slot="footer">
        <a-button key="back" @click="handleCancel"> 取消 </a-button>
        <a-button key="submit" type="primary" :loading="btnLoading" @click="handleOk"> 确定 </a-button>
      </template>
    </a-modal>

    <!-- 查看/编辑抽屉 -->
    <writtingDrawer
      v-if="showDrawer"
      :readOnly="!isLocalData"
      :isLocalData="isLocalData"
      @closeDrawer="handleDrawerClose"
      :planId="planId"
      :pushId="pushId"
      :drawerEditData="{}"
    />
  </div>
</template>

<script>
import api from '@/api/xhsAgencyApi';
import moment from 'moment';
import writtingDrawer from '@/components/xhsAgencyTool/writtingDrawer.vue';
import TopicTreeInput from '@/components/xhsAgencyTool/TopicTreeInput';
import { pushStatusList } from '@/pages/app/xhsAgency/xhsNotePush/data.js';
import { trackRequest } from '@/utils/track';

export default {
  name: 'operateExe',
  components: { writtingDrawer, TopicTreeInput },
  data() {
    const columns = [
      {
        align: 'center',
        title: '计划时间',
        dataIndex: 'planPublishDate',
        width: 180,
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        defaultSortOrder: 'descend',
        customRender: (text) => {
          return text || '-';
        },
      },
      {
        align: 'center',
        title: '选题',
        width: 130,
        scopedSlots: { customRender: 'writtingTheme' },
      },
      {
        align: 'center',
        width: 210,
        title: '媒体号',
        scopedSlots: { customRender: 'mediaAccount' },
      },
      {
        align: 'center',
        title: '预生产',
        scopedSlots: { customRender: 'preStatus' },
      },
      {
        slots: { title: 'statusTitle' },
        align: 'center',
        scopedSlots: { customRender: 'statusData' },
      },
      {
        title: '笔记',
        width: 220,
        scopedSlots: { customRender: 'writtingPreview' },
      },
      {
        align: 'center',
        title: '操作',
        width: 245,
        scopedSlots: { customRender: 'operation' },
      },
    ];
    const personalityTypeList = [
      {
        label: '销售',
        value: 'SALE',
      },
      {
        label: '门店',
        value: 'STORE',
      },
    ];
    const rules = {
      publishStatus: [{ required: true, message: '状态不得为空', trigger: 'change' }],
    };
    return {
      pushStatusList,
      preGenerateLoading: false,
      preStatusList: {
        NOT_STARTED: '未开始',
        IN_PRODUCTION: '生产中',
        PUBLISHING: '发布中',
        PRODUCED: '已生产',
      },
      personalityTypeList,
      form: {
        authorSearch: '',
        operatorId: undefined,
        isPublishDateAsc: false,
        operatorGroupId: undefined,
      },
      checkTimeRange: [moment().startOf('week'), moment().endOf('week')],
      timeRange: {
        本周: [moment().startOf('week'), moment().endOf('week')],
        本月: [moment().startOf('month'), moment().endOf('month')],
        最近30天: [moment().subtract(29, 'days'), moment()],
      },
      columns,
      dataList: [],
      publishStatusList: [
        {
          value: 'ALL',
          text: '全部',
          className: 'all',
          count: 0,
        },
        {
          value: 'NOT_PUBLISH',
          text: '待发布',
          className: 'wait',
          count: 0,
        },
        {
          value: 'PUBLISHING',
          text: '发布中',
          className: 'pushing',
          count: 0,
        },
        {
          value: 'PUBLISHED',
          text: '已发布',
          className: 'done',
          count: 0,
        },
        {
          value: 'EXPIRED',
          text: '已过期',
          className: 'expire',
          count: 0,
        },
      ],
      publishChannelList: [
        {
          value: 'FENGHUOLUN',
          text: '风火轮',
        },
        {
          value: 'BAIFUZHANG',
          text: '百夫长',
        },
        {
          value: 'XIAOHONGSHU',
          text: '小红书',
        },
        {
          value: 'SHARE_PUBLISH_LINK',
          text: '链接发布',
        },
        {
          value: 'OTHER',
          text: '其他',
        },
      ],
      tableLoading: false,
      pagination: {
        current: 1,
        pageSize: 15,
        total: 1,
      },
      showDrawer: false,
      showEditModal: false,
      editModalData: {},
      btnLoading: false,
      rules,
      isLocalData: false,
      currentTab: 'ALL',
      tagStatInfoList: [],
      planId: null,
      pushId: null,
      allMemberList: [],
      allTeamList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    //立即生产内容
    doPreGenerate() {
      this.handleTrack('plan_production_content');
      return new Promise((resolve) => {
        this.preGenerateLoading = true;
        api.doPreGenerateApi().then((res) => {
          if (res.code === 200) {
            this.preGenerateLoading = false;
            this.$message.success('已开始执行生产任务');
            resolve(true);
          } else {
            this.preGenerateLoading = false;
            this.$message.error(res.message);
          }
        });
      });
    },
    async init() {
      await this.getAllMemberList();
      this.getAllTeamList();
      this.currentTab = 'ALL';
      const isPublishDateAsc = this.form.isPublishDateAsc;
      this.form = {
        authorSearch: '',
        operatorId: undefined,
        isPublishDateAsc,
        operatorGroupId: undefined,
      };
      const currentUser = this.allMemberList.find((v) => v.operatorUsername === this.$router.app.user.username);
      if (currentUser?.id) this.form.operatorId = currentUser.id;
      this.checkTimeRange = [moment().startOf('week'), moment().endOf('week')];
      this.pagination = {
        current: 1,
        pageSize: 15,
        total: 1,
      };
      this.searchAllList();
    },
    searchAllList() {
      this.pagination.current = 1;
      this.getStatusList();
      this.getDataList();
    },
    getStatusList() {
      const startDate = this.checkTimeRange[0] ? `${moment(this.checkTimeRange[0]).format('YYYY-MM-DD')}` : undefined;
      const endDate = this.checkTimeRange[1] ? `${moment(this.checkTimeRange[1]).format('YYYY-MM-DD')}` : undefined;
      const params = {
        startDate,
        endDate,
        authorSearch: this.form.authorSearch,
        operatorId: this.form.operatorId,
      };
      this.handleRequest(
        'getPlanStatusList',
        (data) => {
          data.forEach((ele) => {
            this.publishStatusList.forEach((val) => {
              if (ele.publishStatus == val.value) val.count = ele.count;
            });
          });
        },
        params
      );
    },
    handleTagChange() {
      this.pagination.current = 1;
      this.getDataList();
    },
    getDataList() {
      const startDate = this.checkTimeRange[0] ? `${moment(this.checkTimeRange[0]).format('YYYY-MM-DD')}` : undefined;
      const endDate = this.checkTimeRange[1] ? `${moment(this.checkTimeRange[1]).format('YYYY-MM-DD')}` : undefined;
      const params = {
        startDate,
        endDate,
        operatorGroupId: this.form.operatorGroupId,
        authorSearch: this.form.authorSearch,
        operatorId: this.form.operatorId,
        publishStatus: this.currentTab == 'ALL' ? '' : this.currentTab,
        isPublishDateAsc: this.form.isPublishDateAsc,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      this.tableLoading = true;
      this.handleRequest(
        'getPlanExecuteList',
        (data) => {
          this.dataList = data.list.map((v) => {
            let pushStatusText = '-';
            if (v.pushStatus) {
              pushStatusText = this.getStatusText(v.pushStatus);
            }
            return { ...v, pushStatusText };
          });
          this.pagination.total = data.total;
          this.tagStatInfoList = data.tagStatInfoList;
        },
        params
      ).finally(() => (this.tableLoading = false));
    },
    async getAllMemberList() {
      await this.handleRequest(
        'getTeamMember',
        (data) => {
          this.allMemberList = data.list;
        },
        { page: 1, size: 999, isDeleted: 0 }
      );
    },
    getAllTeamList() {
      this.handleRequest(
        'getTeamManageList',
        (data) => {
          this.allTeamList = data.list;
        },
        { page: 1, size: 999 }
      );
    },
    async onTimeChange(dates) {
      this.checkTimeRange = [dates[0], dates[1]];
      this.searchAllList();
      // this.getDataList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    handleTagJump(data) {
      this.handleTrack('go_to_topic');
      const id = data.tagId || undefined;
      if (!id) return this.$message.error('无有效选题');
      const routeUrl = this.$router.resolve({
        name: 'xhsTopicList',
        query: {
          topicId: id,
        },
      });
      window.open(routeUrl.href, '_blank');
    },
    handleChangePlan(data) {
      this.showEditModal = true;
      this.editModalData = JSON.parse(JSON.stringify(data));
      this.editModalData.topicSelectionTagId = data.tagId ? data.tagId : '';
      this.handleTrack('edit_plan');
    },
    findPersonalityType(type) {
      return this.personalityTypeList.find(({ value }) => value == type)?.label || '-';
    },
    findStateType(status) {
      return this.publishStatusList.find(({ value }) => value == status) || { text: '-', className: '' };
    },
    // findChannelType(status) {
    //   return this.publishChannelList.find(({ value }) => value == status)?.text || status;
    // },
    // 小红书主页跳转
    async handleJumpXHS(value) {
      const hide = this.$message.loading('查询中...', 0);
      await this.handleRequest(
        'getXhsAccountPage',
        (data) => {
          window.open(data, '_blank');
        },
        {
          authorId: value.authorId,
        }
      ).finally(() => hide());
    },
    onTableChange(pagination, filters, sorter) {
      const { order } = sorter;
      this.form.isPublishDateAsc = !order ? false : order == 'descend' ? false : true;
      this.getDataList();
    },
    async handlePublish({ id: planId, planPublishDate, tagName, copyWritingContentId, principalId, principalName }) {
      this.getPlanDetail(planId).then((noteDetail) => {
        this.handleTrack('plan_write_note', {
          media_platform: '小红书',
          media_code: noteDetail.authorCode,
          media_neckname: noteDetail.nickname,
          media_id: noteDetail.authorId,
          plan_time: planPublishDate,
          topic: tagName,
          media_member: undefined,
          media_brand: undefined,
        });
      });

      this.$router.push({
        name: 'articleDetail',
        query: copyWritingContentId
          ? { draftId: copyWritingContentId, principalId, principalName }
          : { planId, principalId, principalName },
      });
    },
    getPlanDetail(planId) {
      return new Promise(async (resolve, reject) => {
        const { code, data } = await api.getDetailByPlanId({ planId });
        if (code === 200) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    handleEditPush(value) {
      if (!value.notePushId) return this.$message.info('找不到推送数据');
      this.$router.push({ name: 'articleDetail', query: { pushId: value.notePushId } });
    },
    openDrawer() {
      this.isLocalData = true;
      this.showDrawer = true;
    },
    openXhsNote(link) {
      if (!link) return this.$message.error('找不到笔记链接');
      window.open(link, '_blank');
    },
    openCheckWritting(value) {
      this.isLocalData = false;
      if (value.noteId) {
        this.showDrawer = true;
        this.pushId = null;
        this.planId = value.noteId;
        return;
      }
      if (value.notePushId) {
        this.showDrawer = true;
        this.planId = null;
        this.pushId = value.notePushId;
        return;
      }
      this.$message.info('无可查看数据');
    },
    handleDrawerClose() {
      this.showDrawer = false;
      this.planId = undefined;
    },
    handleCancel() {
      this.$refs.form.clearValidate();
      this.showEditModal = false;
      this.editModalData = {};
    },
    handleOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            id: this.editModalData.id,
            noteId: this.editModalData.noteId,
            noteLink: this.editModalData.noteLink,
            noteTitle: this.editModalData.noteTitle,
            description: this.editModalData.description,
            publishChannel: this.editModalData.publishChannel || '',
            publishStatus: this.editModalData.publishStatus,
            topicSelectionTagId: this.editModalData.topicSelectionTagId,
          };
          // if (params.publishStatus == 'EXPIRED') return this.$message.error('请选择有效状态');
          this.btnLoading = true;
          this.handleRequest(
            'editPlanExecute',
            () => {
              this.$message.success('修改成功');
              this.searchAllList();
              this.handleCancel();
            },
            params
          ).finally(() => (this.btnLoading = false));
        }
      });
    },
    getStatusText(val) {
      return this.pushStatusList.find((status) => status.value == val).text || '-';
    },
    onCopy() {
      this.$message.success('复制成功');
    },
    onCopyError() {
      this.$message.error('复制失败，笔记ID有误');
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
  },
};
</script>

<style lang="less" scoped>
.media-column {
  display: flex;
  margin-top: 10px;
  text-align: left;

  .media-left {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .avatar-left {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .media-right {
    flex: 1;

    .right-txt {
      justify-content: start;

      .logo {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .blue-text {
      color: #1890ff;
      cursor: pointer;
    }
  }

  .avatar-right {
    flex: 1;
    display: flex;
    align-items: center;
  }
}
.status-dot {
  display: inline-block;
  margin-right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.wait {
  background-color: #aaaaaa;
}

.done {
  background-color: #52c41a;
}

.expire {
  background-color: #d9001b;
}

.pushing {
  background-color: #4cb9e4;
}

.line-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.writtingPreview {
  display: flex;
  cursor: pointer;

  .previewLeft {
    margin-right: 5px;
    width: 50px;
    height: 50px;
    background-size: cover;
    background-position: center;
  }

  .previewRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .rightTitle {
      width: 270px;
      font-size: 600;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .rightId {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ae7983;

      .rightIdL {
        width: 165px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.themeList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .themeItem {
    margin: 2px 2px 0 0;
    padding: 0 5px;
    border-radius: 5px;
    color: #fff;
  }
}

/deep/ .ant-form-item {
  margin-bottom: 0;
}

.red-text {
  color: #e65a6b;
}
</style>
